import Helmet from "react-helmet"
import React from "react"
import ExtendedHomepage from "src/components/extendedHomepage"
import HomepageLayout from "src/layouts/homepage"
import NonSearchDisclosure from "src/components/nonsearchdisclosure"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


const homepageData = import("./homepage.json");

export default function HomePage() {
  let disclosure = null;
  if(isChrome()){
    disclosure = <CtaDisclosureNewtab data={homepageData}></CtaDisclosureNewtab>;
  }
  else{
    disclosure = <NonSearchDisclosure data={homepageData}></NonSearchDisclosure>;
  }
  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">{`


          #ctadisclosurenewtab-module--ctadisclaimer, #ctadisclosure-module--ctadisclaimer, #nonsearch-module--nonsearchdisclosure {
            font-weight: 300;
            color: #f0f0f0;
            max-width: 650px;
            font-size: 13px;
          }

          #ctadisclosurenewtab-module--ctadisclaimer p, #ctadisclosure-module--ctadisclaimer p, #nonsearch-module--nonsearchdisclosure p {
            color: #f0f0f0;
          }

          a.ctadisclosurenewtab-module--bold {
            color: #b3d7ff;
          }

          .footerlinkssingle-module--br {
            margin: 75px auto 10px;
          }
        `}</style>

      <title>Easy Print</title></Helmet>
      <ExtendedHomepage data={homepageData}>
        {disclosure}
      </ExtendedHomepage>
    </HomepageLayout>
  )
}
